<template>
  <div class="information">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="back">
            <img src="../../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{ $route.meta.title }}</span>
        </div>

        <div>
          <el-button
            type="primary"
            class="haeder-fuzhi"
            size="small"
            @click="copy(textData)"
            >一键复制</el-button
          >
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/systemSettings/index' }"
        >系统设置</el-breadcrumb-item
      >
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 信息详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 外面的大盒子 -->
    <div class="information-box">
      <div v-loading="loading" class="informaton-main">
        <div class="main-top">
          <div class="main-top-left"></div>
          <!-- 中间部分 -->
          <div class="main-top-center">
            <p
              v-html="textData.title ? textData.title : ''"
              class="center-title"
            ></p>
            <div class="caozuo-center">
              <div
                :class="[
                  'type',
                  textData.tonalState == 2
                    ? 'red'
                    : textData.tonalState == 1
                    ? 'neutral'
                    : 'obverse',
                ]"
              >
                {{
                  textData.tonalState == 2
                    ? "负面"
                    : textData.tonalState == 0
                    ? "正面"
                    : "中性"
                }}
              </div>
              <div v-if="textData.mediaLink != null" class="type website">
                {{ textData.mediaLink }}
              </div>
              <span class="original" @click="lookOriginal(textData.website)">
                <span>
                  <img src="../../../assets/img/views/wenzhang.png" alt />
                </span>
                <span>查看原文</span>
              </span>
              <div class="center-timer">
                发布时间:
                <span>{{
                  textData.releaseTime ? textData.releaseTime : "无"
                }}</span>
              </div>
            </div>
            <div class="center-bottom">
              <span class="center-meiti" style="margin-right: 40px;">
                作者ID:
                <span>{{
                  textData.accurateId ? textData.accurateId : "无"
                }}</span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy(textData.accurateId, 5)"
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <span class="center-meiti">
                媒体性质:
                <span>{{
                  (textData.mediaLink ? textData.mediaLink : "其它") +
                  "/" +
                  (textData.mediaAttribute ? textData.mediaAttribute : "其它") +
                  "/" +
                  (textData.mediaLevel ? textData.mediaLevel : "其它")
                }}</span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="
                      myCopy(
                        [
                          textData.mediaLink,
                          textData.mediaAttribute,
                          textData.mediaLevel,
                        ],
                        1
                      )
                    "
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <span class="center-source">
                来源/作者:
                <span v-html="textData.source ? textData.source : '无'"></span>
                <span>/</span>
                <span v-html="textData.author ? textData.author : '无'"></span>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy([textData.source, textData.author], 2)"
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <span v-if="textData.eventClass" class="center-fenlei">
                事件分类:
                <span>{{ textData.eventList[0] }}</span>
                <el-tooltip
                  v-if="textData.eventClass && textData.eventList.length >= 2"
                  class="item"
                  effect="dark"
                  placement="top"
                >
                  <div slot="content" v-html="textData.eventClass"></div>
                  <span class="gengduo">(更多)</span>
                </el-tooltip>
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy(textData.eventClass, 3)"
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <span class="center-fenlei" v-else>
                事件分类: 无
                <span style="width: 25px; height: 20px">
                  <span
                    class="img my-caozuo"
                    @click="myCopy(textData.eventClass, 3)"
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </span>
              <div class="center-key-box">
                <div class="center-key">
                  关键字:
                  <div v-if="textData.keywordList">
                    <span
                      v-for="(ite, indx) in textData.keywordList"
                      :class="[
                        ite !=
                        textData.keywordList[textData.keywordList.length - 1]
                          ? 'styleMargin'
                          : '',
                      ]"
                      :key="indx"
                      >{{ ite }}</span
                    >
                  </div>
                  <span v-else>无</span>
                </div>
                <div
                  v-if="
                    textData.keyword &&
                    textData.keywordArry &&
                    textData.keywordArry.length > 10
                  "
                  class="gengduo-link-box"
                >
                  <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content" v-html="textData.keyword"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                </div>
                <span
                  style="
                    width: 25px;
                    height: 20px;
                    margin-left: 5px;
                    margin-top: 3px;
                  "
                >
                  <span
                    class="img my-caozuo"
                    @click="myCopy(textData.keyword, 4)"
                  >
                    <img src="../../../assets/img/views/hfz.png" alt />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="main-top-right"></div>
        </div>
        <div style="padding: 32px 210px 0 210px; min-height: 430px">
          <p
            v-html="item ? item : ''"
            class="main-text"
            v-for="(item, index) in textData.contentList"
            :key="index"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMsginfo } from "../../../api/message/list";
export default {
  data() {
    return {
      dataId: this.$route.query.id,
      organId: this.$route.query.organId,
      textData: {},
      loading: false,
    };
  },
  methods: {
    back() {
      this.$router.push({
        path: "/systemSettings/index",
      });
    },
    // 一键复制
    copy(item) {
      this.$publicFun.copyFun(item.copyContent);
    },
    // 个别复制
    myCopy(msg, type) {
      let str = "";
      if (type == 1) {
        msg = msg.map((item) => {
          return item ? item : "其它";
        });
        str = `媒体性质:${msg}`;
      }
      if (type == 2) {
        msg = msg.map((item) => {
          return item ? item : "无";
        });
        str = `来源/作者:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 3) {
        str = `事件分类:${msg}`;
      }else if (type == 5) {
        str = `作者ID:${msg}`;
      }else {
        str = `关键字:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    lookOriginal(web) {
      window.open(web);
    },
    async getMsginfo() {
      this.loading = true;
      const res = await getMsginfo({
        dataId: this.dataId,
        organId: this.organId,
        reportOrganId: this.$store.state.user.organizationId,
      });
      this.textData = res.data.data;
      if (this.textData.eventClass) {
        this.textData.eventList = this.textData.eventClass.split(" ");
        let reg = new RegExp(" ", "g"); //g代表全部
        this.textData.eventClass = this.textData.eventClass.replace(
          reg,
          "<br/>"
        );
      } else {
        this.textData.eventList = [];
      }
      if (this.textData.keyword) {
        this.textData.keywordList = this.textData.keyword.split(" ");
        this.textData.keywordArry = this.textData.keyword.split(" ");
        if (this.textData.keywordList.length > 10) {
          this.textData.keywordList = this.textData.keywordList.slice(0, 10);
        }
        let reg = new RegExp(" ", "g"); //g代表全部
        this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
      }
      if (this.textData.content) {
        this.textData.contentList = this.textData.content.split("\n");
      }
      // this.heightText();
      this.loading = false;
    },
    // heightText() {
    //   if (this.textData.keyword) {
    //     let word = this.textData.keyword.split("<br/>");
    //     if (word[word.length - 1] == "") {
    //       word.pop();
    //     }
    //     word.forEach(ite => {
    //       let reg = new RegExp(ite, "g"); //g代表全部
    //       if (this.textData.title) {
    //         this.textData.title = this.textData.title.replace(
    //           reg,
    //           '<span style="color:red">' + ite + "</span>"
    //         );
    //       }
    //       if (this.textData.contentList) {
    //         for (let i = 0; i < this.textData.contentList.length; i++) {
    //           this.textData.contentList[i] = this.textData.contentList[
    //             i
    //           ].replace(reg, '<span style="color:red">' + ite + "</span>");
    //         }
    //       }
    //     });
    //   }
    // }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.id;
      this.organId = params.organId;
    }
    this.getMsginfo();
  },
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}
.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}
.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}
.information-box {
  padding: 0 24px 24px 24px;
}
.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}
.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}
.styleMargin {
  margin-right: 5px;
}
.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../../assets/img/views/beijing.png");
}
.center-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}
.type {
  padding: 0 8px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  height: 24px;
}

.website {
  margin-left: 16px;
  background-color: #868bff;
}
.original {
  display: flex;
  margin-left: 16px;
  cursor: pointer;
  font-size: 14px;
  color: #2e59ec;
}
.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}
.center-timer {
  display: flex;
  justify-content: space-between;
  width: 213px;
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}
.center-meiti,
.center-source,
.center-fenlei {
  position: relative;
  height: 100%;
  display: flex;
  font-size: 14px;
  color: #999999;
}
.center-source,
.center-fenlei {
  margin-left: 40px;
}
.center-meiti .img,
.center-source .img,
.center-fenlei .img {
  position: absolute;
  right: 0;
  top: 7px;
}
.img {
  margin: 0;
}
.center-bottom {
  height: 35px;
  margin-top: 16px;
}
.center-bottom span {
  display: inline-block;
}
.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}
.gengduo-link {
  font-size: 14px;
  color: #2e59ec;
}
.center-key {
  display: flex;
  font-size: 14px;
  color: #999999;
  margin-right: 5px;
  max-width: 760px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-key div {
  width: 100%;
}
.center-key img {
  position: absolute;
  top: 8%;
  right: 0;
}
.main-text {
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}
.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}
.center-key-box {
  display: flex;
  align-items: center;
}
</style>
